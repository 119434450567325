import React, { FC, ReactNode } from 'react';
import dynamic from 'next/dynamic';

import { AllRights } from '@new/components/AllRights';
import { Flex } from '@new/components/ui';
import { IMapFiltersProps } from '@new/layouts/components/Header/components/Filters/MapFilters';

import { GoToMobile, ModalCookie } from '../../old/Modal';
import { Header } from './../components/Header';

import cls from './SearchLayout.module.css';

const MapFilters = dynamic<IMapFiltersProps>(
	() => import('@new/layouts/components/Header/components/Filters').then(r => r.MapFilters),
	{
		ssr: false,
	}
);
import { Metrics } from '@new/components/Metrics';
import { useMapContext } from '@components/QMap3/context';
import { GoToMobileSmall } from '@new/widgets/GoToMobileSmall';
import { useAuthContext } from '@new/auth';

export enum SectionsMain {
	'header',
	'footer',
}
interface IMainLayoutProps {
	children: ReactNode;
	elements?: SectionsMain[];
	mobileStoreUrl: string | null;
	mobileSiteUrl?: string;
	hasAnonymous?: boolean | null;
}

export const SearchLayout: FC<IMainLayoutProps> = ({
	children,
	elements = [SectionsMain.header, SectionsMain.footer],
	mobileStoreUrl,
	mobileSiteUrl,
	hasAnonymous,
}) => {
	const { clientWidth } = useAuthContext();
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { setTypePageContent, typePageContent, result, setSelectType, history, changeParams, selectType } =
		useMapContext();

	const isHeader = elements.indexOf(SectionsMain.header) > -1;
	const isFooter = elements.indexOf(SectionsMain.footer) > -1;

	return (
		<>
			<Metrics />
			<Flex className={cls.container} direction='column' justifyContent='between'>
				{mobileStoreUrl && <GoToMobileSmall url={mobileStoreUrl} />}
				{isHeader && (
					<Header
						filters={<MapFilters />}
						page='map'
						setTypePageContent={setTypePageContent}
						typePageContent={typePageContent}
						result={result}
						setSelectType={setSelectType}
						currentType={selectType}
						browserHistory={history}
						changeParams={changeParams}
					/>
				)}
				<div className={cls.content} id='content'>
					{children}
				</div>
				{isFooter && <AllRights mobileUrl={mobileSiteUrl} />}
				<ModalCookie />
			</Flex>
		</>
	);
};
