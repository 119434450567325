import { useRouter } from 'next/router';
import Head from 'next/head';
import { SeoConfig } from '@main/src/new/old/Seo/constants';
import useTranslation from 'next-translate/useTranslation';

export interface ICommonSEOProps {
	title?: string;
	description?: string;
	ogType?: string;
	ogImage?: string;
}

export const PageSEO = ({ title, description, ogType, ogImage }: ICommonSEOProps) => {
	const { t } = useTranslation('ui');
	const router = useRouter();
	const ogImageUrl = ogImage || `${SeoConfig.siteUrl}/${t('_seo.default.ogImage')}`;
	const currentTitle = title || t('_seo.default.title');
	const currentDescription = description || t('_seo.default.description');
	const currentOgType = ogType || 'website';

	return (
		<Head>
			<title>{currentTitle}</title>
			{/*<meta name='robots' content='follow, index' />*/}
			<meta name='description' content={currentDescription} />
			<meta property='og:url' content={`${SeoConfig.siteUrl}${router.asPath}`} />
			<meta property='og:type' content={currentOgType} />
			<meta property='og:site_name' content={t('_seo.default.title')} />
			<meta property='og:description' content={currentDescription} />
			<meta property='og:title' content={currentTitle} />
			<meta property='og:image' content={ogImageUrl} />
			<meta name='twitter:card' content='summary_large_image' />
			{/*<meta name='twitter:site' content={siteMetadata.twitter} />*/}
			<meta name='twitter:title' content={currentTitle} />
			<meta name='twitter:description' content={currentDescription} />
			<meta name='twitter:image' content={ogImageUrl} />
		</Head>
	);
};
