
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { GetServerSidePropsContext } from 'next';
import { Provider as ReduxProvider } from 'react-redux';
import { RootModel } from '@app/stores/pages/home';
import { fetchCommonData } from '@common/dataContext/commonData';
import { RematchStore } from '@rematch/core';
import { makeHomeStore } from '../app/stores';
import { NextPageCommon } from '../types/layouts/types';
import { IHomeProps } from '../types/pages';
import { PageSEO } from '@main/src/new/old/Seo';
import { MainLayout } from '@new/layouts';
import { TravelShow } from '@new/widgets/TravelShow';
import { DEFAULT_LOCALE } from '@app/constants/defaults';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { INearCardsProps } from '@new/widgets/NearCards/NearCards';
import { AuthContextWrapper } from '@new/auth';
import React from 'react';
import { Loader } from '../new/components/Loader';
const NearCards = dynamic<INearCardsProps>(() => import('@new/widgets/NearCards').then(r => r.NearCards), {
    ssr: false,
    loading: () => <Loader />,
});
const Home: NextPageCommon<IHomeProps> = ({ common }) => {
    const { locale } = useRouter();
    const { mobileStoreUrl } = common;
    const store: RematchStore<RootModel, RootModel> = makeHomeStore({
        ...common,
        currencyRates: {},
        accommodation: [],
        attraction: [],
        restaurant: [],
        activity: [],
        public_place: [],
    });
    const currentLocale = locale || DEFAULT_LOCALE;
    return (<>
			<PageSEO />
			<AuthContextWrapper hasAnonymous={common.hasAnonymous}>
				<ReduxProvider store={store}>
					<MainLayout mobileStoreUrl={mobileStoreUrl}>
						{/* <PopularCities /> */}
						<NearCards common={common}/>
						<TravelShow detailsRoute={`/${currentLocale}/about/video#top`}/>
					</MainLayout>
				</ReduxProvider>
			</AuthContextWrapper>
		</>);
};
function getServerSideProps(context: GetServerSidePropsContext) {
    const common = fetchCommonData(context.req, context.res, context.locale);
    return {
        props: {
            common,
        },
    };
}
Home.displayName = 'Home';
export default Home;

    async function __Next_Translate__getServerSideProps__19102f0d832__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19102f0d832__ as getServerSideProps }
  